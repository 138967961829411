body {
  margin: 0;
  overflow-x: hidden;
  overflow-y: overlay;
}

/* MUI overrides */
body .MuiInputLabel-root {
  color: rgba(33, 33, 33, 0.8);
  letter-spacing: 0.15px;
  font-size: 16px;
}

body .MuiInputLabel-root.Mui-focused {
  letter-spacing: 0.4px;
}

body .MuiInputLabel-root.Mui-error {
  color: #B00020;
}

body .MuiInputLabel-root.Mui-disabled {
  color: rgba(33, 33, 33, 0.65);
}

body .MuiInputBase-input:-webkit-autofill {
  -webkit-transition: color 999999s ease-out, background-color 999999s ease-out;
  -webkit-transition-delay: 999999s;
}

body .cf-global-loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

body.overflow-hidden {
  overflow: hidden!important;
}


.cf-image-gallery-glo3d-iframe {
  min-height: 360px;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
}

.g-helper-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.3);
  letter-spacing: 0.5px;
}

@media print {
  body {
    min-width: 1400px;
    width: 1400px;
    padding-top: 80px;
    zoom: .8;
  }
}

.grecaptcha-badge {
  visibility: hidden !important;
}
